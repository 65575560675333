// FORMULAIRE __________
// Form
form.row{
	display: block;
	margin-left: -40px;
	margin-right: -40px;
	//width: 100%;
	@media #{$down768} { margin-left: 0; margin-right: 0; }

	.col, .col1, .col2, .col3, .col4, .col5, .col6, .col7,  .col8,  .col9,  .col10, .col11, .col12,
	.col13, .col14, .col15, .col16, .col17, .col18, .col19, .col20, .col21, .col22, .col23, .col24{
		padding: 0 40px;
		@media #{$down768} { padding:0; }
	}
}

// Error
div.error {
	position: absolute;
	padding-top: 7px;
	top: 100%;
	font: 12px/1 $font1;
	color: $color1;
}

.input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field, .uploadBtnWrapper {
	position: relative;
	width: 100%;
	margin-bottom: 80px;

	@media #{$down1024} { margin-bottom: 65px; }
	@media #{$down600} { margin-bottom: 45px; }
}

.uploadBtnWrapper {
	@media #{$down1024} { margin-bottom: 45px; }
	@media #{$down600} { margin-bottom: 35px; }
}


.oc-loading {
	.btn.data-attach-loading { // La classe pour le loading loading sur les boutons submit ajouté par le framework october
		pointer-events: none;

		&::after { 
			margin-left: 10px;
		}
	}
}