// Le fond noir de chaque popup ou sidepanel
#overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0);
    visibility: hidden;
	z-index: 9;
    transition: background 500ms ease, visibility 0ms ease 300ms, z-index 0ms ease 700ms;
}

// L' icone de play
.iconPlay {
    position: absolute;
    width: 50px;
    height: 50px;
    border: solid 2px rgba(#fff, 0.5);
    border-radius: 50%;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    z-index: 1;
    transition: border-color 300ms;

    &:after {
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 5px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent rgba(#fff, 0.5);
        transition: border-color 300ms;
    }
}

#backToTop {
    position: fixed;
    bottom: 350px;
    right: 0;
    opacity: 1;
    z-index: 4000;
    visibility: visible;
    transform: rotate(-90deg);
    transition: opacity 300ms;
    transform-origin: right bottom;

    @media #{$down1300} { opacity: 0; pointer-events: none; }

    &.getAway {
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms, visibility 0ms ease 300ms;
        pointer-events: none;
    }

    a {
        display: flex;
        align-items: center;
        font: 14px/1 $barlow;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        color: white;
        padding: 20px 27px;
        background-color: $ccgjLightBlue;
        font-weight: bold;
        cursor: pointer;

        .topImage{
            position: relative;
            display: block;
            svg{
                width: 12px;
                padding-left: 24px;
                margin-left: 24px;
                box-sizing: content-box;
                position: relative;
                transition: all ease-in 0.3s;
                .fill{
                    fill: white;
                }
            }
            &::after{
                content: '';
                height: 20px;
                width: 1px;
                display: block;
                position: absolute;
                left: 24px;
                top: 50%;
                background-color: $ccgjVeryLightBlue;
                transform: translateY(-50%);
            }
        }

        &:hover{
            .topImage{
                svg{
                    transform: translateX(15%);
                }
            }
        }
    }

}

.yTopPaddingGeneric {
    padding-top: 50px;

    @media #{$down500} { padding-top: 0; }
}