.uploadBtnWrapper {
	position: relative;
	width: 100%;
    display: inline-block;
    @media #{$down1024} {  }

	input[type=file] {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.btnUpload {
		display: block;
		position: relative;
		width: 100%;
		min-height: 65px;
		padding: 25px 60px 25px 25px;
		background: $ccgjVeryLightBlue;
		font: 15px/1 $Roboto;
		color: $ccgjDarkBlue;
		text-align: left;
		border: none;
		cursor: pointer;
		transition: background 0.3s, color 0.3s;

		svg {
			position: absolute;
			width: 15px;
			height: 15px;
			top: calc(50% - 10px);
			right: 30px;

			.fill {
				stroke: $ccgjLightBlue;
				fill: $ccgjLightBlue;
				transition: stroke 0.3s;
			}
		}

		&:hover {
			color: #fff;
			background: $ccgjLightBlue;

			svg {
				.fill {
					stroke: #fff;
					fill: #fff;
				}
			}
		}
	}
}

.uploadBtnWrapper {
	input[type=file].fileAdded {
		& + label{
			.noFile{
				display: none;
			}
		}
	}
}