.genericGalerie {
    position: relative;
    max-width: 1000px;

    &::after {
        display: table;
        content: '';
        clear: both;
    }

    img {
        width: 100%;
    }

    >a {
        position: relative;
        display: block;
        float: left;
        width: calc(100% - 320px);
        height: 300px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        overflow: hidden;

        @media #{$down1600} {
            width: calc(100% - 160px);
        }

        @media #{$down1200} {
            height: 260px;
            width: calc(100% - 140px);
        }

        @media #{$down800} {
            height: 240px;
            width: 100%;
            margin: 0 0 10px 0;
        }

        @media #{$down500} {
            height: 200px;
        }
    }

    ul {
        float: right;
        width: 320px;

        @media #{$down1600} {
            width: 160px;
        }

        @media #{$down1200} {
            width: 140px;
        }

        @media #{$down800} {
            float: none;
            width: calc(100% + 10px);
            margin-left: -5px;
        }

        li {
            display: none;
            float: left;
            width: 50%;
            padding: 0 0 0 20px;

            @media #{$down1600} {
                width: 100%;
            }

            @media #{$down800} {
                width: 25%;
                max-width: 90px;
                padding: 0 5px 0 5px;
            }

            &:first-child,
            &:nth-child(2) {
                display: block;
                padding-bottom: 20px;

                @media #{$down800} {
                    padding-bottom: 0px;
                }
            }

            &:nth-child(2) {
                @media #{$down1600} {
                    padding-bottom: 0;
                }
            }

            &:nth-child(3) {
                display: block;

                @media #{$down1600} {
                    display: none;
                }

                @media #{$down800} {
                    display: block;
                }
            }

            &:nth-child(4) {
                display: block;

                @media #{$down1600} {
                    display: none;
                }

                @media #{$down800} {
                    display: block;
                }
            }

            a {
                position: relative;
                display: block;
                line-height: 0;
            }
        }
    }

    &.nbPic4 ul li {
        @media #{$down1600} {
            &:nth-child(2) a::before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: '+';
                font-size: 40px;
                font-family: Georgia;
                color: #fff;
                z-index: 1;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);

                @media #{$down800} {
                    display: none;
                }
            }
        }
    }

    &.nbPic6 ul li {
        &:nth-child(4) a::before {
            position: absolute;
            left: 50%;
            top: 50%;
            content: '+';
            font-size: 40px;
            font-family: Georgia;
            color: #fff;
            z-index: 1;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }

        @media #{$down1600} {
            &:nth-child(2) a::before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: '+';
                font-size: 40px;
                font-family: Georgia;
                color: #fff;
                z-index: 1;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);

                @media #{$down800} {
                    display: none;
                }
            }
        }
    }
}

.ocList .genericGalerie {
    >a {
        height: 260px;
        width: calc(100% - 140px);

        @media #{$down800} {
            height: 240px;
            width: 100%;
        }

        @media #{$down500} {
            height: 200px;
        }
    }

    ul {
        width: 140px;

        @media #{$down800} {
            width: calc(100% + 10px);
        }

        li {
            display: none;
            width: 100%;

            @media #{$down800} {
                width: 25%;
            }

            &:first-child,
            &:nth-child(2) {
                display: block;
            }

            &:nth-child(2) {
                padding-bottom: 0px;
            }
        }
    }

    &.nbPic4 ul li,
    &.nbPic6 ul li {
        &:nth-child(2) a::before {
            position: absolute;
            left: 50%;
            top: 50%;
            display: block;
            content: '+';
            font-size: 40px;
            font-family: Georgia;
            color: #fff;
            z-index: 1;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }
    }
}
