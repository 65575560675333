.grayOverlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: visible;
    background: rgba(0, 0, 0, 0);
    z-index: 201;
    visibility: hidden;
    transition: background 300ms, visibility 0ms ease 300ms;
}

.show-filterModal .grayOverlay {
    visibility: visible;
    background: rgba(0, 0, 0, 0.7);
    transition: background 400ms;
}