.evenementsHeader{
    background-color: $ccgjVeryLightBlue;
    position: relative;
    @media #{$down1024} { padding-bottom: 1px; }

    .pageTitle{
        position: relative;

        .titlePadding {
            margin-bottom: 48px;
            @media #{$down500} { margin-bottom: 20px; }
        }
    }

    .share{
        margin-top: 100px;
        position: relative;
        z-index: 5;

        @media #{$down1024} {
            margin-top: 0;
        }
        
    }

    #eventsSelectFilter{
        z-index: 700;
        position: absolute;
        top: 50%;
        right: 0;
        width: 250px;
        transform: translateY(-50%);
        @media #{$down900} {
            position: static;
            transform: none;
            width: 100%;
            padding-bottom: 20px;
        }
    }
}

#evenements{
    .eventsSlider{
        margin-top: -385px;
        @media #{$down1024} {
            margin-top: 0;
        }
    }
}

.eventsGrid{
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-right: -40px;
    @media #{$down1300} {
        margin-left: -20px;
        margin-right: -20px;
    }
    /*@media #{$down768} {
        margin-top: 20px;
    }*/
    @media #{$down700} {
        margin-left: 0;
        margin-right: 0;
    }
    
    .event{
        width: 33.33333%;
        padding: 100px 40px 0 40px;
        @media #{$down1300} { padding: 100px 20px 0 20px; }
        @media #{$down1024} { width: 50%; }
        @media #{$down700} { width: 100%; padding-left: 0; padding-right: 0; }

        // Retirer le padding top des 3 premiers éléments
        &:nth-child(-n+3){ 
            padding-top: 0;
            @media #{$down1024} { padding-top: 60px; }
        }

        @media #{$down1024} {
            padding-top: 60px;
            &:nth-child(-n+2){
                padding-top: 0;
            }
        }

        @media #{$down700} {
            &:nth-child(2){
                padding-top: 60px;
            }
        }

        a{
            display: block;
            .eventImage{
                width: 100%;
                padding-top: 63.92405063291139%; //202px/316px
                margin-bottom: 44px; 
                position: relative;
                overflow: hidden;
                @media #{$down700} { margin-bottom: 25px; }
                span{
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
            .h6{
                margin-bottom: 15px;
            }
            .eventTitle{
                font-size: 35px;
                @media #{$down700} { margin-bottom: 25px; }
            }

            &:hover{
                .eventImage{
                    span{
                        transform: scale(1.1);
                    }
                }
                .link{
                    padding: 0 0 0 35px;
                    &::before{
                        width: 25px;
                    }
                }
            }
        }
    }
}

.singleContent{
    padding-right: 10%;
    @media #{$down1024} {
        padding-right: 0;
    }
}
.singleSidebar{
    @media #{$down1024} {
        margin-top: 60px;
        text-align: center;
    }
    .sidebarTitle{
        font-size: 14px;
        text-transform: uppercase;
        font-family: $barlow;
        font-weight: bold;
        letter-spacing: 0.075em;
        color: $ccgjDarkBlue;
    }
    .sidebarSocial{
        color: white;
        display: flex;
        padding: 26px 0;
        border-bottom: #ccc 1px solid;
        margin-bottom: 8px;
        @media #{$down1024} {justify-content: center;}
        .icon{
            display: block;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 20px;
            &:last-child{margin-right: 0;}
            svg{
                width: 16px;
                height: 16px;
                .fill{
                    transition: all ease-in 0.3s;
                    fill: #cfd3d8;
                }
            }
            &:hover{
                svg{
                    .fill{
                        fill: $ccgjLightBlue;
                    }
                }
            }
        }
    }
}

.similarEvents{
    @media #{$down700} {
        padding-bottom: 0;
    }
    hr{
        margin-bottom: 60px;
    }
}


#evenement_detail{
    .singleContent{
        h3:first-of-type{
            margin-top: 35px;
        }
    }
}