.repertoireHeader{
    background-color: $ccgjVeryLightBlue;

    .pageTitle{
        position: relative;
    }

    #repertoireSelectFilter{
        z-index: 700;
        position: absolute;
        top: 50%;
        right: 0;
        width: 250px;
        transform: translateY(-50%);
        @media #{$down900} {
            position: static;
            transform: none;
            width: 100%;
            padding-bottom: 20px;
        }
    }

    @media #{$down900} {
        padding-bottom: 30px;
    }
}


    
.filterSection{
    border-bottom: 1px solid #d9d9d9;
    .filters{
        display: flex;
        @media #{$down900} {
            flex-wrap: wrap;
        }
        a{
            color: #b9bec4;
            font-family: $barlow;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.075em;
            padding: 3px 2px 16px 2px;
            margin: 0 3px 0 3px;
            width: 13px;
            border-bottom: 3px solid transparent;
            text-align: center;
            box-sizing: content-box;
            transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
            &:hover,
            &.active{
                color: $ccgjDarkBlue;
                border-bottom: 3px solid $ccgjLightBlue;
            }

            @media #{$down900} {
                padding: 10px;
                background-color: $ccgjVeryLightBlue;
                color: $ccgjDarkBlue;
                margin-bottom: 6px;
                border: 0;
                &:hover,
                &.active{
                    background-color: $ccgjDarkBlue;
                    color: white;
                    border-bottom: 0;
                }
            }

            &.all{
                width: auto;
                margin-right: 35px;
                @media #{$down900} {
                    margin: 0;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
        }
        ul{
            display: flex;
            flex-wrap: wrap;
        }
    }
}