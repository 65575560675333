// UN TEXTAREA __________
.text-field {
	textarea {
		width: 100%;
		height: 45px;
		min-height: 0;
		padding: 15px 0 2px 0;
		background-color: transparent;
		border: none;
		border-bottom: 2px solid #ccd4dd;
		font: 20px/1.1 $Roboto;
		color: $ccgjDarkBlue;
		resize: none;
		overflow: hidden;
		transition: all ease-in 500ms;
		//transition: border 500ms;

		&:focus {
			min-height: 90px;
			border-bottom-color: $ccgjLightBlue;
			border-bottom-width: 4px;
			padding-bottom: 0;
		}
		&.valid {
			min-height: 90px;
			border-bottom: 2px solid $ccgjLightBlue;
		}
	}

	label {
		position: absolute;
		top: 0px;
        left: 0px;
		font: 14px/45px $Roboto;
		color: $ccgjDarkBlue;
		cursor: text;
		transition: 300ms $ease;
	}

	textarea:focus + label,
	textarea.valid + label,
	textarea.notEmpty + label{
		min-height: 90px;
		top: -12px;
		font-size: 11px;
		line-height: 12px;
		cursor: default;
		pointer-events: none;
	}
}
