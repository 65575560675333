// HEADER STANDARD _________________________
header {
	height: 88px;
	background-color: $ccgjVeryLightBlue;
	position: relative;
	.logo{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		@media #{$down1024} {z-index: 1}
		a{
			position: absolute;
			display: block;
			height: 88px;
			padding: 0 28px;
			width: 250px;
			@media #{$down400} {
				padding: 0 20px;
				width: 90px;
			}
			svg{
				width: 190px;
				height: 35px;
				position: absolute;
				display: block;
				top: 50%;
				transform: translateY(-50%);
				@media #{$down800} { width: auto; height: 50px; }
				.fill{
					transition: fill ease-in 0.3s;
					&.blue{fill:$ccgjBlue;}
					&.lightBlue{fill:$ccgjLightBlue;}
				}
				@media #{$down800} {
					.fill.letter{
						display: none;
					}
				}
			}
		}
	}

	nav.primary{
		padding-left: 220px;
		height: 100%;
		ul{
			display: flex;
			list-style-type: none;
			height: 100%;
			padding: 0 30px;
			li{
				a{
					height: 100%;
					font-size: 13px;
					display: block;
					font-weight: 600;
					color: $ccgjDarkBlue;
					padding: 36px 18px 0 18px;
					&:after{
						content: '';
						opacity: 0;
						height: 2px;
						background-color: $ccgjLightBlue;
						width: 100%;
						display: block;
						position: relative;
						top: 3px;
						transition: all ease-in 0.2s;
					}

					&:hover{
						&:after{
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.header-right{
		position: absolute;
		display: flex;
		top: 0;
		right: 0;
		height: 100%;
		.menu-toggle{
			text-transform: uppercase;
			//font-family: $barlow;
			display: flex;
			align-items: center;
			padding: 0 26px;
			cursor: pointer;
			transition: all ease-in 0.2s;
			font-size: 13px;
			//font-size: 12px;
			font-weight: 600;
			@media #{$down400} {
				.menuText{ display: none; }
			}
			.hamburger{
				display: inline-block;
				margin-top: 0;
				margin-left: 12px;
				z-index: 2;
				.inner,
				.inner::before,
				.inner::after{
					background-color: $ccgjDarkBlue;
				}
			}
			&:hover{
				opacity: 0.6;
			}
		}
		.header-search{
			color: white;
			background-color: $ccgjDarkBlue;
			padding: 0 45px;
			display: flex;
			align-items: center;
			font-weight: 600;
			transition: all ease-in 0.2s;
			font-size: 13px;
			cursor: pointer;
			@media #{$down800}{ padding: 0 35px; }
			svg{
				margin-right: 18px;
				width: 18px;
				height: 18px;
				@media #{$down800}{margin-right: 0;}
				.fill{
					stroke: white;
					fill: white;
					transition: all ease-in 0.2s;
				}
			}
			&:hover{
				color: $ccgjLightBlue;
				svg{
					.fill{
						stroke: $ccgjLightBlue;
						fill: $ccgjLightBlue;
					}
				}
			}
		}
	}

	// HEADER DE L'ACCUEIL _____________________
	&.home{
		background-color: $ccgjBlue;

		.logo{
			a{
				svg{
					.fill{
						fill: white;
					}
				}
			}
		}

		nav.primary{
			ul{
				li{
					a{
						color: white;
					}
				}
			}
		}

		.header-right{
			.menu-toggle{
				color: white;
				transition: all ease-in 0.3s;
				.hamburger{
					.inner,
					.inner::before,
					.inner::after{
						transition: all ease-in 0.3s;
						background-color: white;
					}
				}
				&:hover{
					color: $ccgjLightBlue;
					opacity: 1;
					.hamburger{
						.inner,
						.inner::before,
						.inner::after{
							background-color: $ccgjLightBlue;
						}
					}
				}
			}
		}

	}

}


.showOverlayDropDown,
.showOverlayDropDownSearch{
	header {
		.logo{
			a{
				svg{
					.fill{
						&.blue{fill:white;}
						&.lightBlue{fill:white;}
					}
				}
			}
		}
	}
}

