.checkbox-field {
	display: block;
	position: relative;
	padding-left: 30px;
	font-size: 15px;
	line-height: 1.733333333333333em;// 26px/15px
	color: $ccgjDarkBlue;
	user-select: none;

    input { display: none; }
    
    label{
        cursor: pointer;
    }

	.icon {
		position: absolute;
		width: 20px;
		height: 20px;
		top: 0.9em; left: 0;
		border: 1px solid $ccgjDarkBlue;
		transform: translateY(-50%) translateY(-1.5px);
		transition: background 0.2s;

		&:after {
			content: "";
			position: absolute;
			opacity: 0;
			visibility: hidden;
			transform: rotate(45deg) scale(0.5);
			transition: transform 0.2s, opacity 0.2s, visibility 0ms ease 0.2s;
		}
	}

	input:checked ~ .icon { background-color: $ccgjDarkBlue; }
	input:checked ~ .icon:after {
		opacity: 1;
		visibility: visible;
		transform: rotate(45deg);
        transition: transform 0.2s, opacity 0.2s;
	}

	.icon:after {
		left: 6.5px;
		top: 3.5px;
		width: 3px;
		height: 8px;
		border: solid white;
		border-width: 0 2px 2px 0;
	}
}