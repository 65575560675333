// IMPORTATION __________
// Plugins
@import '../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css';
@import '../node_modules/slick-carousel/slick/slick-theme.scss';
@import '../node_modules/slick-carousel/slick/slick.scss';

// Général
@import 'essentials/variables.scss';
@import 'essentials/resets.scss';
@import 'essentials/grille.scss';
@import 'essentials/fonts.scss';
@import 'essentials/classes.scss';
@import 'essentials/helper.scss';
@import 'essentials/generic.scss';

// Components
@import 'components/tiroirs.scss';
@import 'components/hamburgerBtn.scss';

@import 'components/chosen.scss';
@import 'components/homeSlider.scss';
@import 'components/eventsSlider.scss';
@import 'components/publications.scss';
@import 'components/testimonials.scss';
@import 'components/partners.scss';
@import 'components/formulaire/formulaire.scss';
@import 'components/formulaire/input.scss';
@import 'components/formulaire/textarea.scss';
@import 'components/formulaire/upload.scss';
@import 'components/formulaire/checkbox.scss';


    // Animation
    @import 'components/animation/scrollFire.scss';
    @import 'components/animation/transition.scss';
    // Formulaire
    //@import 'components/formulaire/formulaire.scss';
    //@import 'components/formulaire/input.scss';
    //@import 'components/formulaire/textarea.scss';

// Partials
@import 'partials/header.scss';
@import 'partials/footer.scss';
@import 'partials/page-header.scss';
@import 'partials/cta-devenir-membre.scss';
@import 'partials/cta-repertoire-membres.scss';
@import 'partials/cta-calendrier.scss';
@import 'partials/sharemodal.scss';
@import 'partials/overlay/dropDownMenu.scss';
@import 'partials/overlay/dropDownSearch.scss';
@import 'partials/overlay/filterOverlay.scss';
@import 'partials/overlay/cookieOverlay.scss';
@import 'partials/galerie.scss';

// Pages
@import 'pages/accueil.scss';
@import 'pages/evenements.scss';
@import 'pages/publications.scss';
@import 'pages/travailler-a-la-chambre.scss';
@import 'pages/devenir-membre.scss';
@import 'pages/repertoire.scss';
@import 'pages/recherche.scss';
@import 'pages/nous-joindre.scss';
@import 'pages/erreurs.scss';
