#topSlider{
    position: relative;
    background-color: $ccgjBlue;
    #topSliderNav{
        position: absolute;
        bottom: 0;
        right: 37.5%;
        z-index: 99;
        background-color: $ccgjDarkBlue;
        width: 150px;
        height: 55px;
        display: flex;
        a{
            width: 50%;
            display: flex;
            position: relative;
            svg{
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%,-50%);
                width: 12px;
                transition: all ease-in 200ms;
                .fill{
                    fill: white;
                    stroke: white;
                }
            }
            &.prev{
                transform: rotate(180deg);
                &::after{
                    content: '';
                    height: 20px;
                    width: 1px;
                    background-color: #576577;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            &:hover{
                svg{
                    left: 56%;
                }
            }
        }
    }
    #topSliderPager {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 37.5%;
        z-index: 99;
        background-color: white;
        height: 54px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        span {
			display: inline-block;
            font-size: 13px;
			color: $ccgjDarkBlue;
            font-weight: bold;
            width: 15px;
            text-align: center;
            &.currentSlide{
                color: $ccgjLightBlue;
            }
			&.spacer {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				line-height: 1px;
				height: 1px;
				width: 50px;
				margin: 0 10px;
				background: #e6e8eb;
				@media #{$down1024} { width: 30px; }
				
				&::after {
					position: absolute;
					left: 0;
					bottom: 0;
					top: 0;
					width: 0;
					content: '';
					background: $ccgjDarkBlue;
                }
                
                &.anim{
                    &::after {
                        animation: pager 5s linear infinite;
                    }
                }
                &.anim.reset{
                    &::after {
                        animation: pager2 5s linear infinite;
                    }
                }
			}

			&:last-child { margin-bottom: 0; }
		}
    }
    #slickSlides{
        .slide{
            position: relative;
            overflow: hidden;
            .slideLeft,
            .slideRight{
                height: 715px;
            }
            .slideLeft{
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                position: relative;

                @media #{$down1024} {
                    height: 460px;
                    margin-top: 190px;
                }
                @media #{$down500} {margin-top: 125px;}

                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    background-image: url(../images/content/home_slider_gradient.png);
                    background-repeat: repeat-y;
                    background-position: right;

                    @media #{$down1024} {
                        background-image: url(../images/content/home_slider_vertical_gradient.png);
                        background-repeat: repeat-x;
                        background-position: top;
                    }
                }
            }
            .slideRight{
                display: flex;
                align-items: center;
                padding: 0;
                padding-bottom: 54px;
                position: relative;
                @media #{$down1024} {
                    display: block;
                    position: absolute;
                    bottom: 318px;
                    padding-bottom: 0;
                    height: auto;
                }
                @media #{$down500} {top: 30px;}
                @media #{$down400} {top: 40px;}
                .content{
                    position: relative;
                    left: -76px;
                    z-index: 2;
                    transform: translateY(30%);
                    opacity: 0;
                    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.6s, cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
                    @media #{$down1024} {
                        position: static;
                        width: 100%;
                        padding: 0 8%;
                    }
                    .text{
                        font-size: 95px;
                        font-weight: bold;
                        color: white;
                        font-family: $barlow;
                        line-height: 1em;
                        text-transform: uppercase;
                        margin-bottom: 50px;

                        @media #{$down1024} {
                            font-size: 80px;

                            br{
                                display: none;
                            }
                        }
                        @media #{$down500} {font-size: 60px;}
                        @media #{$down400} {font-size: 55px;}
                        //@media #{$down400} {font-size: 50px;}
                        b,strong,span{
                            color: $ccgjLightBlue;
                        }
                    }
                }

                .logoBg{
                    position: absolute;
                    width: 127%;
                    max-width: 680px;
                    z-index: 1;
                    right: -39%;
                    bottom: -7%;
                    @media #{$down1024} {display: none;}
                    svg{
                        width: 100%;
                        height: auto;
                        opacity: 0.2;
                        .fill{
                            &.blue { fill: #0c4777 }
                            &.lightBlue { fill: $ccgjLightBlue }
                            &.back{  display: none; }
                        }
                    }
                }
            }

            &.slick-current{
                .slideRight{
                    .content{
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}


// ANIM CYCLE PAGER
@keyframes pager {
	0%   { width: 0; }
	100% { width: 100%; }
}
@keyframes pager2 {
	0%   { width: 0; }
	100% { width: 100%; }
}