.rechercheHeader{
    background-color: $ccgjVeryLightBlue;

    .pageTitle{
        position: relative;
        h1,h2{
            margin-bottom: 0;
        }
    }

    #providerSelectFilter{
        z-index: 700;
        position: absolute;
        top: 50%;
        right: 0;
        width: 250px;
        transform: translateY(-50%);
        @media #{$down900} {
            position: static;
            transform: none;
            width: 100%;
            padding-bottom: 20px;
        }
    }
    @media #{$down900} {
        padding-bottom: 30px;
    }
}

#moreResultsWrapper{
    a{
        display: block;
        padding: 38px 50px;
        border-bottom: solid 1px #cbd3d0;
        h6{
            margin-top: 15px;
            margin-bottom: 15px;
        }
        h3{
            margin-top: 0;
            color: $ccgjDarkBlue;
            mark{
                color: $ccgjDarkBlue;
            }
        }

        &:hover{
            .link{
                padding: 0 0 0 35px;
                &::before{
                    width: 25px; 
                }
            }

        }
    }
}