// STYLES __________
body{
	font-family: $Roboto;
}

.content{
	img{
		max-width: 100%;
	}
}

h1, .h1 {
	font-family: $barlow;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 80px;
	color: $ccgjDarkBlue;
	margin-bottom: 48px;
	@media #{$down1300} { font-size: 70px; }
	@media #{$down1024} { font-size: 50px; }
	@media #{$down500}  { font-size: 45px; }
	@media #{$down400}  { font-size: 40px; }
}

h2, .h2 {
	font-family: $barlow;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 60px;
	color: $ccgjDarkBlue;
	margin-bottom: 48px;
	@media #{$down1024} { font-size: 40px; }
	@media #{$down768}  { font-size: 34px; }
}

h3, .h3 {
	font-family: $barlow;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 35px;
	color: $ccgjLightBlue;
	margin-bottom: 35px;
	margin-top: 70px;
    @media #{$down1024} { font-size: 28px; }
	@media #{$down768}  { font-size: 26px; }
}

h4, .h4 {
	font-size: 20px;
	font-weight: bold;
	color: $ccgjDarkBlue;
	margin-bottom: 20px;
	margin-top: 45px;
    @media #{$down1024} { font-size: 18px; }
	@media #{$down768}  { font-size: 16px; }
}

h5, .h5 {
	font-size: 30px;
	font-weight: 300;
	color: $ccgjDarkBlue;
	margin-bottom: 35px;
	@media #{$down1024} { font-size: 30px; }
	@media #{$down768}  { font-size: 26px; }
}

h6, .h6 {
	font-size: 13px;
	font-weight: 600;
	color: $ccgjLightBlue;
	margin-bottom: 35px;
	.separator {
		display: inline-block;
		padding: 0 15px;
	}
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
	&:first-child{ margin-top: 0 }
	&:last-child{ margin-bottom: 0 }
}

p {
	font-size: 17px;
	line-height: 1.647058823529412em; //28px/17px
	color: $ccgjDarkBlue;
	margin: 20px 0;

	&.small { font-size: 15px; }
	&.legend{
		color: #91959b;
		font-size: 11px;
	}

	a.link{
		text-transform: none;
		padding: 0;
		font-family: $Roboto;
		letter-spacing: normal;
		font-size: 1em;
		&::before,
		&::after{
			content: '';
			background-color: $ccgjLightBlue;
			position: absolute;
			height: 2px;
			width: 100%;
			left: 0;
			top: auto;
			bottom: -2px;
		}

		&::before{
			background-color: $ccgjDarkBlue;
			width: 0;
			z-index: 2;
		}

		&::after{
			z-index: 1;
		}

		&:hover{
			padding: 0;
			&::before{
				width: 100%;
			}
		}
	}
}

.light{
	p{
		color: white;
		a.btn{
			color: white;
		}
		a.link{
			color: white;
			&::before{
				background-color: white;
			}
		}
	}
}

a {
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
}

hr{
	background-color: $ccgjLightBlue;
	width: 100px;
	height: 3px;
	border: 0;
	display: inline-block;
}

blockquote,
p.large{
	color: $ccgjDarkBlue;
	font-size: 30px;
	line-height: 1.5em;
	font-weight: 300;
	@media #{$down768} { font-size: 23px; }
}

blockquote{
	padding-left: 55px;
}

mark{
	background-color: $ccgjLightBlue;
}

// Le lien



.dynamic a:not(.btn),
.link {
    position: relative;
    display: inline-block;
    padding: 0 10px 0 25px;
	font: 14px/1 $barlow;
	font-weight: bold;
    color: $ccgjDarkBlue;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.075em;
	transition: padding 300ms;
	&::before{
		content: '';
		position: absolute;
		height: 2px;
		width: 15px;
		top: 6px;
		left: 0;
		background: $ccgjLightBlue;
		transition: width 300ms;
	}

	&:hover{
		padding: 0 0 0 35px;
		&::before{
			width: 25px;
		}
	}
}

.dynamic a:not(.btn){
	text-transform: none;
	padding: 0;
	font-family: $Roboto;
	letter-spacing: normal;
	font-size: 1em;
	&::before,
	&::after{
		content: '';
		background-color: $ccgjLightBlue;
		position: absolute;
		height: 2px;
		width: 100%;
		left: 0;
		top: auto;
		bottom: -2px;
	}

	&::before{
		background-color: $ccgjDarkBlue;
		width: 0;
		z-index: 2;
	}

	&::after{
		z-index: 1;
	}

	&:hover{
		padding: 0;
		&::before{
			width: 100%;
		}
	}
}

// Le bouton
.btn{
	background-color: $ccgjLightBlue;
	color: white;
	font-size: 13px;
	line-height: 1.538461538461538em;//20px/13px
	font-weight: 600;
	padding: 18px 25px;
	letter-spacing: 0.02em;
	display: inline-block;
	position: relative;
	z-index: 1;
	&:after{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		content: '';
		background: $ccgjDarkBlue;
		z-index: -1;
		transform: scaleX(0);
		transform-origin: center left;
		transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 80ms;
	}
	&:hover{
		&:after{
			transform: scaleX(1);
		}
	}

	&.alt{
		background-color: transparent;
		color: $ccgjDarkBlue;
		font-family: $barlow;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 0.075em;
		transition: padding 300ms;
		padding-left: 30px;
		&:after{
			content: normal;
		}
		&::before{
			content: '';
			position: absolute;
			height: 2px;
			width: 20px;
			top: 50%;
			left: 0;
			background: $ccgjLightBlue;
			transition: width 300ms;
			transform: translateY(-50%);
		}
		&:hover{
			padding-left: 40px;
			&::before{
				width: 30px;
			}
		}
	}
}

#transition-wrapper{
	a,
	button,
	span{
		&.oc-loading{
			&::after{
				height: 15px;
				width: 15px;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				margin: 0;
				transform-origin: 50% 50%;
				background-color: transparent;
				opacity: 0.7;
				margin-left: -7px;
				margin-top: -10px;
			}
		}
	}
}

.dynamic ul,
.dynamic ol,
.liste {
	color: $ccgjDarkBlue;
	margin: 20px 0;
	li{
		position: relative;
		padding-left: 34px;
		margin-top: 15px;
		&:first-child{
			margin-top: 0;
		}
		&::before{
			content:"";
			background-color: $ccgjLightBlue;
			position: absolute;
			width: 18px;
			height: 2px;
			top: 0.4em;
			left: 0;
			transform: translateY(-50%);
		}
	}
	&.small { font-size: 15px; }

	&.w-icon{
		li{
			border-bottom: 1px solid #e5e5e5;
			position: relative;
			padding-left: 0;
			&::before{
				content: normal;
			}
			a{
				display: block;
				padding: 20px 3%;
				font-size: 15px;
				color: $ccgjDarkBlue;
				font-weight: bold;
				transition: all ease-in 0.3s;
				.listIcon{
					position: absolute;
					right: 40px;
					top: 50%;
					text-transform: uppercase;
					letter-spacing: 1px;
					transform: translateY(-50%);
					svg{
						top: 15px;
						width: 15px;
						.fill{
							fill: $ccgjLightBlue;
						}
					}
				}
				&:hover{
					color: $ccgjLightBlue;
				}
			}
		}
	}
}

.dynamic{
	img{
		&.fr-fic.fr-dii{
			display: inline-block;
			vertical-align: bottom;
			&.fr-fil{
				float: left;
				margin: 5px 5px 5px 0;
				max-width: calc(95%);
			}
			&.fr-fir{
				float: right;
				margin: 5px 0 5px 5px;
				max-width: calc(95%);
			}
		}
	}
}

.share{
	color: $ccgjDarkBlue;
	font-size: 13px;
	font-weight: 600;
	display: flex;
	height: 45px;
	align-items: center;
	transition: all ease-in 0.3s;
	svg{
		width: 15px;
		height: 15px;
		margin-left: 22px;
		.fill{
			fill: $ccgjDarkBlue;
			transition: all ease-in 0.3s;
		}
	}
	&:hover{
		color: $ccgjLightBlue;
		svg .fill{
			fill: $ccgjLightBlue;
		}
	}
	&.width{
		width: 150px;
	}
}

.tab{
	display: table;
	width: 100%;
	color: $ccgjDarkBlue;
	.tabRow{
		display: table-row;
		.tabCell{
			display: table-cell;
			padding: 15px 35px;
			@media #{$down700} { padding: 15px; }
			&.color{
				color: white;
				background-color: $ccgjLightBlue;
			}
		}
		&:nth-child(odd){
			background-color: #f2f4f6;
			.tabCell.color{
				background-color: #9dc7e7;
			}
		}
	}
}

::selection {
	color: white;
	background: rgba(0,42,84,0.99);
  }
::-moz-selection {
	color: white;
	background: rgba(0,42,84,0.99);
}