.ctaRepertoireMembres{
    position: relative;
    //@media #{$down1024} { margin-bottom: 40px; }
    @media #{$down700} { background-color: $ccgjVeryLightBlue; }
    .wrapper{
        min-height: 650px;
        position: relative;
        @media #{$down1200} { min-height: 450px; }
        @media #{$down700} { min-height: auto; }
        .ctaContent{
            width: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 3;
            @media #{$down1024} { width: 56%; }
            @media #{$down700} {
                position: static;
                transform: none;
            }
            .h6{
                text-transform: uppercase;
                font-size: 14px;
                font-family: $barlow;
                letter-spacing: 0.075em;
            }
            .ctaText{
                font-size: 40px;
                line-height: 1.5em; //60px/40px
                color: $ccgjDarkBlue;
                font-weight: bold;
                margin-bottom: 60px;
                @media #{$down1200} {
                    font-size: 34px;
                    margin-bottom: 40px;
                }
                @media #{$down800} { font-size: 30px; }
                @media #{$down700} { font-size: 20px; }
                
            }
        }
        .ctaContentMobile{
            .h6{
                text-transform: uppercase;
                font-size: 14px;
                font-family: $barlow;
                letter-spacing: 0.075em;
            }
            .ctaText{
                font-size: 40px;
                line-height: 1.5em; //60px/40px
                color: $ccgjDarkBlue;
                font-weight: bold;
                margin-bottom: 60px;
                @media #{$down1200} {
                    font-size: 34px;
                    margin-bottom: 40px;
                }
                @media #{$down800} { font-size: 30px; }
                @media #{$down700} { font-size: 20px; }
                
            }
        }
    }
    .blueBg{
        position:absolute;
        height: 100%;
        width: 66%;
        right: 0;
        top: 0;
        background-color: $ccgjVeryLightBlue;
        z-index: 1;
        @media #{$down1200} { width: 58%; }
        @media #{$down700} { display: none; }
    }
    .imageBg{
        position:absolute;
        height: calc(100% - 20px);
        width: 45%;
        right: 0;
        bottom: 70px;
        background-image:url(../images/content/cta/membres.jpg);
        background-size: cover;
        background-position: center;
        z-index: 2;
        @media #{$down1024} { width: 40%; }
        @media #{$down700} {
            bottom: 40px
        }
    }
    .imageBgMobile{
        height: 250px;
        width: 100%;
        background-image:url(../images/content/cta/repertoire.jpg);
        background-size: cover;
        background-position: center;
        z-index: 2;
    }
}