// BOUTON HAMBURGER __________
.hamburger {
	//display: none;
	margin-top: 9px;
	background: transparent;
	border: 0;
	font: inherit;
	color: inherit;
	text-transform: none;
	overflow: visible;
	cursor: pointer;
	z-index: 500;
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	@media #{$down1100} { display: inline-block; }

	.box {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		height: 18px;
	}

	.inner {
		display: block;
		top: 50%;
		margin-top: -1px;

		&, &::before, &::after {
			width: 30px;
			height: 2px;
			background-color: $color2;
			border-radius: 0;
			position: absolute;
			transition-property: transform;
			transition-duration: 0.15s;
			transition-timing-function: ease;
		}

		&::before, &::after { content: ""; display: block; }
		&::before { top: (6px + 2px) * -1; }
		&::after { bottom: (6px + 2px) * -1; }
	}
}



// ANIMATION DU BOUTON HAMBURGER __________
.spinAnim {
	.inner {
		transition-duration: 0.22s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

		&::before {
			transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
		}

		&::after {
			transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}
	}
}

.showOverlayMobile {
	.inner {
		transform: rotate(225deg);
		transition-delay: 0.12s;
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

		&::before {
			top: 0;
			opacity: 0;
			transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
		}

		&::after {
			bottom: 0;
			transform: rotate(-90deg);
			transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	}
}
