.careerHeader{
    background-color: $ccgjVeryLightBlue;
    @media #{$down900} { padding-bottom: 20px; }
    .pageTitle{
        position: relative;
        @media #{$down900} { 
            h1{
                margin-bottom: 20px;
            }    
        }
    }
    #jobsSelectFilter{
        z-index: 700;
        position: absolute;
        top: 50%;
        right: 0;
        width: 250px;
        transform: translateY(-50%);
        @media #{$down900} {
            position: static;
            transform: none;
            width: 100%;
            padding-bottom: 20px;
        }
    }
}

.careerContent{
    @media #{$down900} { 
        padding-top: 10px;
    }
}

.jobs{
    border-top: #c6c6c6 1px solid;
    .job{
        padding: 40px 225px 40px 60px;
        width: 100%;
        position: relative;
        border-bottom: #c6c6c6 1px solid;
        @media #{$down1024} { padding: 40px 225px 40px 40px; }
        @media #{$down700} { padding: 40px; text-align: center;}
        @media #{$down700} { padding: 30px; }
        .jobContent{
            color: $ccgjDarkBlue;
            font-size: 22px;
            font-weight: 600;
            transition: color ease-in 0.2s;
            @media #{$down800} { font-size: 18px; }
        }
        .jobButton{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 60px;
            width: 135px;
            @media #{$down700} {
                position: static;
                transform: none;
                text-align: center;
                margin: 20px 0 0 0;
                width: auto;
            }
            .btn{
                padding-right: 0;
                padding-top: 0;
                padding-bottom: 0;
            }

        }
        &:hover{
            .jobContent{
                color: $ccgjLightBlue;
            }
            .jobButton{
                .btn{
                    padding-left: 40px;
                    &::before{
                        width: 30px;
                    }
                }
            }
        }
    }
}

#individualJob{
    .jobHeader{
        background-color: $ccgjVeryLightBlue;
        .pageTitle{
            padding-right: 285px;
            position: relative;
            @media #{$down1024} { padding-right: 0; }
            .backBtn{
                position: absolute;
                top: 50%;
                right: 0;
                @media #{$down1024} { position: static; padding-bottom: 30px; }
            }
        }
    }
    .jobContent{
        max-width: 1100px;
    }
    .jobForm{
        hr{
            margin-bottom: 40px;
        }
    }
}

