.contactHeader{
    background-color: $ccgjVeryLightBlue;
    .pageTitle{
        position: relative;
        h1{
            margin-bottom: 0;
        }
        .share{
            position: absolute;
            right: 0;
            top: 85%;
            transform: translateY(-50%);
            @media #{$down1024} { display: none }
        }
    }
}

.directions{
    position: sticky;
    color: white;
    margin-top: -40px;
    top: 30px;
    @media #{$down1024} { position: static }
    .directionsTop{
        background-color: $ccgjDarkBlue;
        padding-top: 25px;
        padding-bottom: 25px;
        h3{
            font-size: 25px;
            letter-spacing: 0.02em;
            font-weight: bold;
            color: white;
            margin: 0;
            text-transform: none;
            font-family: $Roboto;
        }
    }
    .directionsBottom{
        background-color: $ccgjBlue;
        padding-top: 40px;
        padding-bottom: 40px;
        h6{
            text-transform: uppercase;
            font-family: $barlow;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.075em
        }
        p{
            font-size: 15px;
            letter-spacing: 0.02em;
        }
        p.big{
            font-size: 20px;
        }
    }
}

.contactFormCol{
    padding-left: 60px;
    @media #{$down1024} { padding-left: 0; }
    h2{
        margin-bottom: 15px;
    }
    hr{
        margin-bottom: 40px;
    }
}