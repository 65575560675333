.sectionTestimonials{
    position: relative;
    #testimonialsSliderNav{
        position: absolute;
        bottom: 0;
        right: 50%;
        z-index: 99;
        background-color: $ccgjDarkBlue;
        width: 150px;
        height: 55px;
        display: flex;
        @media #{$down1024} {
            right: auto;
            left: 0;
            bottom: auto;
            top: 0;
        }
        a{
            width: 50%;
            display: flex;
            position: relative;
            svg{
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%,-50%);
                width: 12px;
                transition: all ease-in 200ms;
                .fill{
                    fill: white;
                    stroke: white;
                }
            }
            &.prev{
                transform: rotate(180deg);
                &::after{
                    content: '';
                    height: 20px;
                    width: 1px;
                    background-color: #576577;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            &:hover{
                svg{
                    left: 56%;
                }
            }
        }
    }
    #testimonialsSliderPager{
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 150px;
        z-index: 99;
        background-color: white;
        height: 54px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        @media #{$down1024} {
            display: none;
        }
        span {
			display: inline-block;
            font-size: 13px;
			color: $ccgjDarkBlue;
            font-weight: bold;
            width: 15px;
            text-align: center;
            &.currentSlide{
                color: $ccgjLightBlue;
            }
			&.spacer {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				line-height: 1px;
				height: 1px;
				width: 50px;
				margin: 0 10px;
				background: #e6e8eb;
				@media #{$down1024} { width: 30px; }
				
				&::after {
					position: absolute;
					left: 0;
					bottom: 0;
					top: 0;
					width: 0;
					content: '';
					background: $ccgjDarkBlue;
                }
                
                &.anim{
                    &::after {
                        animation: pager 5s linear infinite;
                    }
                }
                &.anim.reset{
                    &::after {
                        animation: pager2 5s linear infinite;
                    }
                }
			}

			&:last-child { margin-bottom: 0; }
		}
    }

    .testimonialsSlides{
        .slide{
            min-height: 770px;
            height: 769px; //Fix IE11
            display: flex;
            overflow: hidden;
            @media #{$down1024} { display: block; height: auto; min-height: auto;}
            .slideImage{
                background-repeat: no-repeat;
                background-size: cover;
                background-position: top center;
                position: relative;
                @media #{$down1024} {
                    width: 100%;
                    padding-bottom: 60%;
                }
                .credit{
                    color: white;
                    background-color: rgba(0,0,0,0.5);
                    display: inline-block;
                    font-size: 13px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 10px 20px;
                    @media #{$down1024} { font-size: 12px; }
                }
            }
            .slideContent{
                background-color: $ccgjVeryLightBlue;
                display: flex;
                align-items: center;
                color: $ccgjDarkBlue;
                position: relative;

                @media #{$down1024} {
                    height: 450px;
                    text-align: center;
                }

                .slideText{
                    transform: translateY(30%);
                    opacity: 0;
                    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.6s, cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
                }

                .testimonial{
                    font-size: 40px;
                    line-height: 1.5em; //60px/40px
                    font-weight: bold;
                    @media #{$down1500} { font-size: 35px }
                    @media #{$down800} { font-size: 30px }
                    @media #{$down500} { font-size: 25px }
                    @media #{$down400} { font-size: 22px }
                }
                .author{
                    margin-top: 45px;
                    font-size: 14px;
                    font-family: $barlow;
                    letter-spacing: 0.075em;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: $ccgjLightBlue;
                    @media #{$down400} { font-size: 12px }
                    strong,b{
                        color: $ccgjDarkBlue;
                    }
                }
                &::after{
                    content: '';
                    background-image: url(../images/content/decoration.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 511px;
                    height: 449px;
                    position: absolute;
                    bottom: -220px;
                    right: -80px;
                    z-index: 1;
                    transform: rotate(124deg);
                    @media #{$down1024} { content: normal; }
                } 
            }

            &.slick-current{
                .slideContent{
                    .slideText{
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}