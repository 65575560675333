.publiciteBigBox,
.publiciteSmallBox{
    box-sizing: content-box;
    .slick-list{
        .publicite{

            &>a>img{
                display: block;
                max-width: 100%;
            }

            &.double{
                display: flex;
                margin: 0 -10px;
                .pub{
                    margin: 0 10px;
                    transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 3s;
                    opacity: 0;
                    width: 50%;
                    img{
                        max-width: 100%;
                    }
                    &.addDelay{ transition-delay: 0.4s; }
                    &.addMoreDelay{ transition-delay: 0.8s; }
                }
            }

            &.slick-active{
                &.double{
                    .pub{
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.footerPartners{
    display: flex;
    padding-top: 30px;
    @media #{$down1024} { display: none }
    .footerPartner{
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        width: 16.666666666666666666%;
        padding-bottom: 30px;
        img{
            transition: all ease-in 0.3s;
            padding: 0 30px;
            max-width: 100%;
            max-height: 60px;

        }
        &:nth-child(3) {
            img{
                transition: all ease-in 0.3s;
                padding: 0 30px;
                max-width: 115%;
                max-height: 60px;
            }
        }

        &:hover{
            img{
                opacity: 0.6;
            }
        }
    }
}
.newsletterCta{
    margin-bottom: -85px;
    z-index: 2;
    position: relative;
    .newsletterContent{
        color: white;
        background-color: $ccgjBlue;
        @media #{$down400} {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .ctaText{
            font-family: $barlow;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 35px;
            line-height: 1.114285714285714em; //39px/35px
            @media #{$down1024} {
                margin-bottom: 30px;
                text-align: center;
                font-size: 24px;
            }
            @media #{$down400} {font-size: 20px;}
        }
        .newsletterFooterForm{
            display: flex;
            input,
            a.button{
                height: 70px;
                line-height: 70px;
                border:1px solid $ccgjLightBlue;
                padding: 0 35px;
                background-color: transparent;
                color: white;
                font-size: 17px;
                border-radius: 0;
                -webkit-appearance: none;
                @media #{$down400} {
                    height: 55px;
                    padding: 0 10px;
                    font-size: 14px;
                    line-height: 55px;
                }

            }
            input{
                flex-grow: 1;
                border-right: 0;
                width: calc(100% - 145px);
                &::placeholder{
                    color: white;
                }
            }
            a.button{
                position: relative;
                width: 145px;
                cursor: pointer;
                font-size: 13px;
                padding: 0 25px;
                transition: all ease-in 0.3s;
                text-align: center;
                @media #{$down700} { width: 70px; }
                @media #{$down400} {
                    width: 45px;
                }
                
                span{
                    display: inline-block;
                    padding-right: 10px;
                    @media #{$down700} { display: none; }
                }
                svg{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateX(0);
                    width: 12px;
                    transition: all ease-in 0.3s;

                    @media #{$down700} { transform: translateY(-50%) translateX(-50%); }

                    .fill{
                        stroke: white;
                        fill: white;
                    }
                }
                &:hover{
                    background-color: $ccgjLightBlue;
                    svg{
                        transform: translateY(-50%) translateX(+25%); 
                    }
                }
            }

            div.error{
                color: white;
                padding-top: 15px;
            }
        }
    }
}
footer {
    background-color: $ccgjDarkBlue;
    padding-top: 106px;
    padding-bottom: 20px;
    .footerMenu{
        display: flex;
        padding: 0;
        .footerLogo{
            svg{
                width: 50px;
                height: 50px;
                .fill{
                    transition: all ease-in 0.2s;
                    fill: white;
                }
            }
            &:hover{
                svg{
                    .fill{
                        fill: $ccgjLightBlue;
                    }
                }
            }
        }
        ul{
            display: flex;
            @media #{$down768} { display: none; }
            li{
                padding-left: 30px;
                padding-top: 20px;
                @media #{$down1024} {
                    &:first-child{
                        padding-left: 0;
                    }
                }
                a{
                    color: white;
                    transition: all ease-in 0.2s;
                    font-size: 13px;
                    &:hover{
                        color: $ccgjLightBlue;
                    }
                }
            }
        }
    }
    .footerSocial{
        color: white;
        display: flex;
        padding: 0;
        padding-top: 20px;
        justify-content: flex-end;
        @media #{$down600} { justify-content: center; }
        .icon{
            display: block;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 35px;

            @media #{$down1024} { margin-left: 20px; }

            svg{
                width: 16px;
                height: 16px;
                .fill{
                    transition: all 200ms linear 200ms;
                    fill: #8095a9;
                }
            }

            &:hover{
                svg{
                    .fill{
                        fill: white;
                    }
                }
            }
        }
    }
    .subfooter{
        color: #8095a9;
        font-size: 11px;
        padding-top: 45px;
        a{
            color: #8095a9;
            transition: all linear cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
            &:hover{
                color: white;
            }
        }
        .col12{
            padding: 0;
            @media #{$down600} {
                text-align: center;
                &:last-child{
                    margin-top: 12px;
                }
            }
        }
    }
}
