// MEDIA QUERY
$down1800: 'screen and (max-width: 1800px)';     $up1800: 'screen and (min-width: 1801px)';
$down1700: 'screen and (max-width: 1700px)';     $up1700: 'screen and (min-width: 1701px)';
$down1600: 'screen and (max-width: 1600px)';     $up1600: 'screen and (min-width: 1601px)';
$down1500: 'screen and (max-width: 1500px)';     $up1500: 'screen and (min-width: 1501px)';
$down1400: 'screen and (max-width: 1400px)';     $up1400: 'screen and (min-width: 1401px)';
$down1300: 'screen and (max-width: 1300px)';     $up1300: 'screen and (min-width: 1301px)';
$down1200: 'screen and (max-width: 1200px)';     $up1200: 'screen and (min-width: 1201px)';
$down1100: 'screen and (max-width: 1100px)';     $up1100: 'screen and (min-width: 1101px)';
$down1024: 'screen and (max-width: 1024px)';     $up1024: 'screen and (min-width: 1025px)';
$down1000: 'screen and (max-width: 1000px)';     $up1000: 'screen and (min-width: 1001px)';
$down900 : 'screen and (max-width: 900px)';      $up900 : 'screen and (min-width: 901px)';
$down800 : 'screen and (max-width: 800px)';      $up800 : 'screen and (min-width: 801px)';
$down768 : 'screen and (max-width: 768px)';      $up768 : 'screen and (min-width: 769px)';
$down700 : 'screen and (max-width: 700px)';      $up700 : 'screen and (min-width: 701px)';
$down600 : 'screen and (max-width: 600px)';      $up600 : 'screen and (min-width: 601px)';
$down500 : 'screen and (max-width: 500px)';      $up500 : 'screen and (min-width: 501px)';
$down400 : 'screen and (max-width: 400px)';      $up400 : 'screen and (min-width: 401px)';
$down330 : 'screen and (max-width: 330px)';      $up330 : 'screen and (min-width: 331px)';
$down300 : 'screen and (max-width: 300px)';      $up300 : 'screen and (min-width: 301px)';

// TECHNIQUE
$gaps: 1%; // Padding entre chaque colonne d'une row
$ease: cubic-bezier(0.215, 0.61, 0.355, 1);
$duration: 800ms;

// COULEURS
$color1 : #981B1D; // Rouge bourgogne
$color2 : #333;    // Noir

$ccgjBlue: #002a54;
$ccgjDarkBlue: #0e223b;
$ccgjLightBlue: #a4cfef;
$ccgjVeryLightBlue: #ecf5fc;
$ccgjTextGray: #a3abb6;

// FONTS
$font1 : 'Roboto', sans-serif;
$Roboto : 'Roboto', sans-serif;
$barlow : 'Barlow Condensed', sans-serif;
