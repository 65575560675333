// UN CHAMP DE TEXTE __________
.input-field {
	input {
		width: 100%;
		height: 45px;
		padding: 0 0 2px 0;
		border: none;
		border-bottom: 2px solid #ccd4dd;
		font: 20px/45px $Roboto;
		color: #000;
		background: transparent;
		transition: 300ms ease;

		&:focus { border-bottom-color: $ccgjLightBlue; border-bottom-width: 4px; padding:0; }
		&.valid { border-bottom: 1px solid $ccgjLightBlue; }
	}

	label {
		position: absolute;
		top: 0px;
        left: 0;
		font: 14px/45px $Roboto;
		color: $ccgjDarkBlue;
		cursor: text;
		transition: 300ms $ease;
	}

	input{
		&:focus,
		&.valid,
		&.error,
		&.notEmpty{
			& + label {
				top: -12px;
				font-size: 11px;
				line-height: 12px;
				cursor: default;
				pointer-events: none;
			}
		}
	}

	input.vide + label {
		top: 0px;
		font: 16px/45px $Roboto;
	}
}
