.tabCategAdhesion{
    .checkbox-field{
        margin-bottom: 0;
    }

    .tabRow{
        .tabCell{
            font-size: 15px;
            @media #{$down1024} {
                font-size: 12px;
                .checkbox-field{
                    font-size: 12px;
                }
            }
            &.addition{
                text-align: right;
                width: 340px;
                @media #{$down1024} { width: 300px }
                @media #{$down700} { display: none; }
                .operator{
                    padding: 0 16px;
                    display: inline-block;
                    &.last{
                        padding-right: 0;
                    }
                }
            }
            &.total{
                width: 130px;
                text-align: right;
                font-weight: bold;
                @media #{$down1024} { width: 120px }
                @media #{$down700} { width: 80px }
            }
        }
    }
}

.categories{
    font-size: 20px;
    color: $ccgjDarkBlue;
    h3{
        margin-bottom: 15px;
    }
}

.checkboxes{
    margin-bottom: 40px;
    .checkbox-field{
        margin-bottom: 20px;
        &.floatLeft{
            width: 120px;
        }
    }
}

#formMembre{
    .chosen-container{
        .chosen-single{
            width: 100%;
            height: 45px;
            padding: 0 0 2px 0;
            border: none;
            border-bottom: 2px solid #ccd4dd;
            line-height: 45px;
            font: 14px/45px "MaisonNeue", sans-serif;
            color: #0e223b;

            &>div>b{
                content: '';
                height: 9px;
                width: 13px;
                background: url(../images/content/icons/blue_arrow.svg) no-repeat center center;
                background-size: 13px auto;
                display: inline-block;
                margin: 0 15px;
                transition: all ease-in 0.3s;
            }
        }
        .chosen-drop{
            padding-top: 0;
            margin-top: 45px
        }
        &.hasChanged{
            .chosen-single{
                font: 20px/45px "MaisonNeue", sans-serif;
                color: #000;
            }
        }

        &.chosen-with-drop{
            .chosen-single{
                &>div>b{
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
