// VISUEL DES TRANSITIONS DE PAGE ET DU CHARGEMENT
// La barre de chargement
#loaderbar {
    position: absolute;
    width: 0%;
    height: 5px;
    top: 0;
    z-index: 999999;
    background-color: $ccgjLightBlue;
    transition: width 100ms;
}


// La transition entre les pages
#page-transition, #landing {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	z-index: 9998;
	background: white;
	visibility: hidden;
	transition: opacity 600ms, visibility 0ms ease 600ms;

	.transitionContainer{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		color: $ccgjBlue;
		svg{
			width: 227px;
			height: 192px;
			.fill{
				fill: $ccgjBlue;
			}
		}
	}


}


// Transition entre les pages
.transition {
	#page-transition {
		opacity: 1;
		visibility: visible;
		transition: opacity 600ms ease 1000ms;
	}
}

// Premier chargement de page
.chargement{
	#landing {
		opacity: 1;
		visibility: visible;
		transition: opacity 600ms ease;
		.transitionContainer{
			.fill{
				position: relative;
				transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
				&.step1{ animation: step1 1s }
				&.step2{ animation: step2 1s }
				&.step3{ animation: step3 1s }
				&.step4{ animation: step4 1s }
			}
		}
	}
}

@keyframes step1 {
	0% { opacity: 0; transform: translateY(30px) }
	10% { opacity: 0; transform: translateY(30px) }
	25% { opacity: 1; transform: translateY(0) }
}

@keyframes step2 {
	0% { opacity: 0; transform: translateY(30px) }
	20% { opacity: 0; transform: translateY(30px) }
	50% { opacity: 1; transform: translateY(0) }
}

@keyframes step3 {
	0% { opacity: 0; transform: translateY(30px) }
	30% { opacity: 0; transform: translateY(30px) }
	75% { opacity: 1; transform: translateY(0) }
}

@keyframes step4 {
	0% { opacity: 0; transform: translateY(30px) }
	40% { opacity: 0; transform: translateY(30px) }
	100% { opacity: 1; transform: translateY(0) }
}