.sectionPublications{
    .lastPublication{
        padding-right: 110px;
        @media #{$down1024}{ padding-right: 0; }

        hr{
            margin-top: 15px;
        }

        a{
            font-size: 16px;
            color: $ccgjTextGray;
            width: 100%;
            @media #{$down1024}{ padding-top: 30px; }

            .image,
            .h6,
            .h3,
            .text{
                display: block;
            }

            .image{
                width: 100%;
                padding-top: 104%;
                margin-bottom: 44px; 
                position: relative;
                overflow: hidden;
                @media #{$down1024}{ padding-top: 50%; }
                @media #{$down600}{ margin-bottom: 20px }
                span{
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }

            .h6{
                margin-bottom: 15px;
            }

            .h3{
                font-size: 35px;
                line-height: 1.085714285714286em; //38px/35px
                font-weight: bold;
                font-family: $barlow;
                text-transform: uppercase;
                color: $ccgjDarkBlue;
                margin-top: 0;
            }

            .text{
                margin-bottom: 40px;
                line-height: 1.875em;// 30px/16px
            }

            &:hover{
                .image{
                    span{
                        transform: scale(1.1);
                    }
                }
                .link{
                    padding: 0 0 0 35px;
                    &::before{
                        width: 25px;
                    }
                }
            }

        }
    }
    .listPublications{
        @media #{$down1024}{ text-align: center; }
        h2{
            margin-bottom: 15px;
        }
        .publications{
            padding-top: 50px;
            padding-bottom: 30px;
            .publication{
                font-size: 20px;
                line-height: 1.3em;//26px/20px
                color: $ccgjDarkBlue;
                font-weight: bold;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #e6e8eb;
                padding-bottom: 30px;
                margin-bottom: 30px;
                transition: all ease-in 0.3s;
                .image{
                    display: block;
                    width: 120px;
                    height: 120px;
                    position: relative;
                    overflow: hidden;
                    span{
                        position: absolute;
                        display: block;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
                    }
                }
                .content{
                    display: block;
                    padding-left: 36px;
                    width: calc(100% - 120px);
                    text-align: left;
                    .h6,
                    .title{
                        display: block;
                    }
                    .h6{
                        margin-bottom: 12px;
                    }
                    @media #{$down700}{
                        .h6{
                            margin-bottom: 10px;
                        }
                    }
                    @media #{$down400}{
                        width: 100%;
                        padding-left: 0;
                        padding-top: 20px;
                    }
                }

                &:hover{
                    color: $ccgjLightBlue;
                    .image{
                        span{
                            transform: scale(1.1);
                        }
                    }
                }

                @media #{$down400}{ 
                    display: block;
                    .image{
                        width: 100%;
                        height: 215px;
                    }
                }
            }
        }
    }
}

.show_1024{
    .h2{
        margin-bottom: 15px;
    }
}