.sectionPartners{
    h2{
        margin-bottom: 15px;
        font-size: 45px;
    }
    .partnersTable{
        display: flex;
        flex-wrap: wrap;
        @media #{$down1024} { margin: auto; }
        @media #{$down600} { padding-top: 0; }
        .partner{
            width: 33.333333%;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid #e6ebee;
            border-left: 1px solid #e6ebee;
            padding: 30px;
            @media #{$down800} { width: 50%; }
            @media #{$down600} { width: 100%; }
                
            img{
                max-width: 100%;
                filter: grayscale(100%);
                opacity: 0.3;
                transition: all ease-in 0.3s;
                max-height: 50px;
                @media #{$down1024} {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }

            // 3 premiers éléments
            &:nth-child(-n+3) {
                border-top: 0;
                @media #{$down800} { border-top: 1px solid #e6ebee; }
            }

            &:nth-child(3n-2){
                border-left:0;
                @media #{$down800} { border-left: 1px solid #e6ebee; }
            }

            @media #{$down800} {
                // 2 premiers éléments
                &:nth-child(-n+2){
                    border-top: 0;
                }
                &:nth-child(2n-1){
                    border-left: 0;
                }
            }

            @media #{$down600} {
                height: 180px;
                border-left: 0 !important;
                &:nth-child(1){
                    border-top: 0;
                }
                &:nth-child(2){
                    border-top: 1px solid #e6ebee;
                }
            }

            @media #{$down400} {
                height: 130px;
            }

            &:hover{
                img{
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        }
    }
}