.eventsSlider{
    margin-top: 100px;
    @media #{$down1024}{ margin-top: 35px; }
    @media #{$down768}{ margin-top: 65px; }
    #blockEvents{
        position: relative;
        #slickEventsOver{
            position: absolute;
            top: 180px;
            left: calc(120px + 115px );
            z-index: 2;
            width: 240px;

            @media #{$down1300}{ left: 180px; }
            @media #{$down1200}{ left: 170px; }
            @media #{$down1024}{ left: 100px; top: 80px }
            @media #{$down800}{ left: 180px; top: 80px }
            @media #{$down768}{ left: 60px; top: 60px }
            @media #{$down500}{
                position: static;
                width: 100%;
            }


            .eventSmallImage{
                width: 245px;
                height: 140px;
                background-size: cover;
                background-repeat: no-repeat;
                @media #{$down500}{ 
                    width: 100%;
                    height: 260px;
                }
            }
        }

        #slickEventsNav{
            background-color: #0e223b;
            width: 150px;
            height: 55px;
            position: absolute;
            top: 140px;
            left: 0;
            z-index: 99;
            display: flex;
            @media #{$down500}{
                left: auto;
                top: 205px;
                right: 0;
            }

            a{
                width: 50%;
                display: flex;
                position: relative;
                svg{
                    left: 50%;
                    top: 50%;
                    position: absolute;
                    transform: translate(-50%,-50%);
                    width: 12px;
                    transition: all ease-in 200ms;
                    .fill{
                        fill: white;
                        stroke: white;
                    }
                }
                &.prev{
                    transform: rotate(180deg);
                    &::after{
                        content: '';
                        height: 20px;
                        width: 1px;
                        background-color: #576577;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
                &:hover{
                    svg{
                        left: 56%;
                    }
                }
            }
        }

        #slickEvents{
            .slick-list{
                background: none; //fix erreur image non trouvée slicknav
                .event{
                    padding-left: 120px;
                    padding-right: 150px;
                    @media #{$down1200}{
                        //padding-left: 5%;
                    }
                    @media #{$down1024}{
                        padding: 40px;
                    }
                    @media #{$down768}{
                        padding: 0;
                    }
                    @media #{$down500}{ padding: 0 }
                    .eventContent{
                        position:relative;
                        background-color: $ccgjBlue;

                        .eventInfo{
                            padding: 90px 60% 90px 115px;
                            color: $ccgjTextGray;
                            font-size: 16px;

                            @media #{$down1300}{ padding: 60px 45% 60px 60px; }
                            @media #{$down1024}{ padding: 60px; }
                            @media #{$down500}{ padding: 22px; padding-bottom: 45px; }

                            .overTitle{
                                color: $ccgjLightBlue;
                                font-weight: 600;
                                padding-top: 260px;
                                @media #{$down1024}{ padding-top: 225px; }
                                @media #{$down500}{ padding-top: 25px; }
                                .separator{
                                    display: inline-block;
                                    padding: 0 15px;
                                }
                            }
                            h4{
                                padding-top: 18px;
                                color: white;
                                font-size: 35px;
                                font-family: $barlow;
                                font-weight: bold;
                                text-transform: uppercase;
                                margin-top: 0;
                            }

                            .exerpt{
                                font-size: 16px;
                                line-height: 1.875; //30px/16px
                                padding-bottom: 45px;
                                color: $ccgjTextGray;
                                margin: 0;

                            }

                            .link{
                                color: white;
                            }
                        }

                        .eventImage{
                            position:absolute;
                            right: -150px;
                            top: -47px;
                            width: 60%;
                            height: 100%;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center;

                            @media #{$down1300}{
                                width: 55%;
                            }

                            @media #{$down1024}{
                                display: none;
                            }

                            .date{
                                position: absolute;
                                background-color: white;
                                top: 0;
                                right: 37px;
                                width: 106px;
                                text-align: center;
                                font-size: 55px;
                                color: $ccgjLightBlue;
                                font-family: $barlow;
                                font-weight: bold;
                                padding-top: 38px;
                                padding-bottom: 28px;
                                span{
                                    font-size: 14px;
                                    margin-top: 18px;
                                    text-transform: uppercase;
                                    color: $ccgjDarkBlue;
                                    display: block;
                                    font-family: $Roboto;
                                    font-weight: 500;
                                }
                            }

                            .btn{
                                position: absolute;
                                bottom:0;
                                left: 0;
                            }
                        }
                    }
                }

            }
        }
    }
}

#evenements{
    .eventsSlider{
        .eventContent{
            .eventImage{
                .btn{
                    display: none;
                }
            }
        }
    }
}