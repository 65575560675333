.breadcrumb {
    margin-bottom: 30px;

    @media #{$down768} { margin-bottom: 20px; }

    li {
        display: inline-block;
        position: relative;
        height: 20px;
        vertical-align: middle;

        &:not(:last-child) { padding-right: 25px; }

        &:not(:last-child):after {
            content: '-';
            position: absolute;
            width: 10px;
            height: 12px;
            top: 3px;
            right: 5px;
            opacity: 0.5;
        }

        a {
            transition: opacity 300ms;

            svg {
                width: 20px;
                height: 20px;

                polyline, line, path { fill: transparent; }
            }

            &:hover { opacity: 0.5; }
        }

        a, p, span {
            display: inline-block;
            margin: 0px;
            color: $ccgjDarkBlue;
            font-size: 13px;
            line-height: 1.2em;
            font-weight: 600;
        }
        p {
            color: $ccgjLightBlue;
        }
    }
}

.pageHeader{
    display: flex;
    min-height: 775px;
    height: 774px; //Fix IE11
    position: relative;
    padding-bottom: 45px;
    @media #{$down1200} { min-height: 600px; height: auto; }
    @media #{$down900} { padding-top: 370px }
    @media #{$down800} { min-height: auto; padding-bottom: 0; }
    .pageHeaderContent{
        background-color: $ccgjVeryLightBlue;
        margin-bottom: 80px;
        @media #{$down900} {
            width: 100%;
            position: relative;
            margin-bottom: 0;
            padding-top: 50px;
            background-color: transparent;
        }
        .pageTitle{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-bottom: 260px;
            max-width: 37%;
            @media #{$down900} { 
                padding-bottom: 0;
                position: static;
                transform: none;
                max-width: 100%;
            }
            h1{
                margin-bottom: 0;
                @media #{$down500} { 
                    margin-bottom: 40px;
                }
            }
        }
        .box{
            color: white;
            background-color: $ccgjBlue;
            font-size: 30px;
            font-weight: 300;
            line-height: 1.5em; //45px/30px
            position: absolute;
            width: 57%;
            bottom: 0;
            z-index: 3;
            @media #{$down1200} { font-size: 20px }
            @media #{$down1024} { width: 75% }
            @media #{$down900} { 
                bottom: 100%;
                z-index: 3;
                right: 0;
                width: 82%;
                padding: 28px;
                font-size: 13px;
            }
        }
        .share{
            position: absolute;
            bottom: 0;
            left: 65%;
            @media #{$down1024} { left: 81%; }
            @media #{$down900} {
                top: 0;
                left: auto;
                right: 25px;
            }

        }
    }

    .image{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        z-index: 2;
        @media #{$down900} { 
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 370px;
        }
    }
}