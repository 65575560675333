#page-home {


    .eventsSlider{
        position: relative;
        &::after{
            content: '';
            background-image: url(../images/content/decoration.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: 511px;
            height: 449px;
            position: absolute;
            bottom: -171px;
            right: -197px;
            z-index: -1;
            @media #{$down1024} { content: normal; }
        } 
    }


    .nextEventFixed{
        background-color: white;
        position: fixed;
        z-index: 99;
        right: 2%;
        display: flex;
        height: 110px;
        opacity: 0;
        top: 100vh;
        transition: top cubic-bezier(0.19, 1, 0.22, 1) 0.5s, opacity cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
        transform: translate3d(0px,0px,0px);
        @media #{$down700} {
            right: 0;
            width: 100%;
        }
        .nextEventImage{
            width: 120px;
            height: 100%;
            overflow: hidden;
            position: relative;
            .image{
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
        .nextEventContent{
            padding: 15px 50px 15px 30px;
            font-size: 15px;
            display: flex;
            align-items: center;
            width: 270px;
            @media #{$down700} { width: calc(100% - 120px); }
            .contentAlign{
                display: block;

                .nextTitle{
                    display: block;
                    font-family: $barlow;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: $ccgjLightBlue;
                    margin-bottom: 12px;
                }
            }
        }

        &:hover{
            .nextEventImage{
                .image{
                    transform: scale(1.1);
                }
            }
        }

        a.overlay{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }

        .close{
            position: absolute;
            top: 0;
            right: 0;
            width: 11px;
            height: 11px;
            padding: 15px;
            box-sizing: content-box;
            z-index: 3;
            cursor: pointer;
            svg{
                width: 11px;
                height: 11px;
                .fill{
                    fill: $ccgjDarkBlue;
                    stroke: $ccgjDarkBlue;
                    transition: all ease-in 0.2s;
                }
            }
            &:hover{
                svg{
                    .fill{
                        fill: $ccgjLightBlue;
                        stroke: $ccgjLightBlue;
                    }
                }
            }
        }

        &.active{
            top: calc((100vh - 110px) - 4%);
            opacity: 1;
            @media #{$down700} { top: calc(100vh - 110px); }
        }
    }

}
