#overlayDropDown {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: -100vh;
    background: $ccgjDarkBlue;
    background: $ccgjDarkBlue;
    z-index: 100;
    transition: top .6s cubic-bezier(0.19, 1, 0.22, 1);

    color: white;
    opacity: 1;

    overflow: hidden;

    .mobilemenuLogo{
        position: absolute;
        top: 42px;
        left: 26px;
        @media #{$down800} { top: 24px; }
        svg{
            height: 50px;
            width: auto;
            @media #{$up800} {
                width: 190px;
                height: 35px;
                display: block;
            }
            .fill{
                fill: white;
            }
        }
    }

    .fullLogo {
        display: none;
        @media #{$down1024} {
            display: block;
        }
        @media #{$down800} {
            display: none;
        }
    }

    @media #{$down400} {
        .mobilePadding{
            padding-top: 60px;
            padding-bottom: 80px;
        }
    }

    .btnClose{
        position: absolute;
        top: 0;
        right: 215px;
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        padding: 38px 38px 38px 20px;
        transition: all ease-in 200ms;
        cursor: pointer;
        z-index: 100;
        @media #{$down800} { right: 100px; }
        @media #{$down500} { right: 50px; }
        svg{
            height: 16px;
            width: 16px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -1px;
            .fill{
                stroke: white;
                transition: all ease-in 200ms;
            }
        }
        &:hover{
            color: $ccgjLightBlue;
            svg{
                .fill{
                    stroke: $ccgjLightBlue;
                }
            }
        }
    }

    .mainMenu,
    .secondaryMenu{
        transition: opacity ease-in-out 0.6s, top cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
        transition-delay: 0.6s;
        position: relative;
        top: 60px;
        opacity: 0;
    }

    .secondaryMenu{
        transition-delay: 0.8s;
    }

    .mainMenu{
        @media #{$down1024} {
            margin-top: 60px;
        }
        ul {
            transition: opacity 200ms, visibility 0ms linear 300ms;

            li {
                padding-top: 42px;
                a, p {
                    margin-bottom: 0px;
                    font-size: 45px;
                    font-family: $barlow;
                    color: $ccgjLightBlue;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding-right: 30px;
                    @media #{$down500} {font-size: 38px; }
                    @media #{$down400} {font-size: 30px; }

                    .arrow{
                        display:block;
                        top: 50%;
                        position: absolute;
                        right: 0;
                        transform: translateY(-50%) rotate(90deg);
                        transition: all cubic-bezier(0.19, 1, 0.22, 1) 200ms, visibility 0ms linear 300ms;
                        svg{
                            display: block;
                            .fill{
                                fill: $ccgjLightBlue;
                            }
                        }
                    }
                }
                p { opacity: 0.1; }
                a {
                    position: relative;
                    transition: color 300ms;

                    &:hover {
                        color: white;
                        .arrow svg .fill{
                            fill: white;
                        }
                    }
                }

                ul.subLvl1{
                    display: none;
                    li{
                        padding-top: 22px;
                        a{
                            font-family: $Roboto;
                            font-size: 20px;
                            font-weight: normal;
                            text-transform: none;
                            padding: 0;
                            padding-bottom: 4px;
                            position: relative;
                            &:after{
                                content: '';
                                opacity: 0;
                                height: 2px;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                display: block;
                                background-color: $ccgjLightBlue;
                                position: absolute;
                                transition: all ease-in 0.2s;
                            }
                        }

                        &.active a,
                        a:hover{
                            &:after{
                                opacity: 1;
                            }
                        }
                    }
                    &.open{
                        li{
                            a{
                                color: white;
                            }
                        }
                    }
                }

                &:first-child{
                    padding-top: 0;
                }

                &.active{
                    a{
                        color: white;
                        .arrow{
                            transform: translateY(-50%) rotate(270deg);
                            svg{
                                .fill{
                                    fill: white;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    .secondaryMenu{
        @media #{$down1024} { margin-top: 100px; }
        @media #{$down500} { margin-top: 60px; }
        ul{
            margin-top: -10px;
            margin-bottom: 40px;
            li{
                a{
                    color: white;
                    font-weight: 600;
                    padding: 10px 0 2px 0;
                    margin-bottom: 8px;
                    position: relative;
                    &:after{
                        content: '';
                        opacity: 0;
                        height: 2px;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        display: block;
                        background-color: $ccgjLightBlue;
                        position: absolute;
                        transition: all ease-in 0.2s;
                    }
                }

                &.active a,
                a:hover{
                    &:after{
                        opacity: 1;
                    }

                }
            }
        }

        .address{
            font-size: 15px;
            line-height: 1.466666666666667em; //22px/15px
            .tel a {
                font-size: 20px;
                line-height: 40px;
                font-weight: bold;
                color: $ccgjLightBlue;
            }
            .contact a {
                color: #fff;
                font: inherit;
            }
        }

        .social{
            display: flex;
            padding-top: 42px;
            @media #{$down1024} {
                padding-bottom: 50px;
            }
            .icon{
                display: block;
                width: 16px;
                height: 16px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-right: 16px;

                svg{
                    width: 16px;
                    height: 16px;
                    .fill{
                        transition: all 200ms linear 200ms;
                        fill: #8095a9;
                    }
                }

                &:hover{
                    svg{
                        .fill{
                            fill: white;
                        }
                    }
                }

                @media #{$down1024} {
                    width: 25px;
                    height: 25px;
                    margin-right: 30px;
                    svg{
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }


}


.showOverlayDropDown {
    // css nécéssaire pour activer une section
    @mixin activeSection() {
        visibility: visible;
        opacity: 1;
        transition: opacity 200ms linear 400ms;
    }

    // Ajout de la bonne section active
    &.showPartenaire #sectionPartenaire { @include activeSection(); }
    &.showTarif #sectionTarif           { @include activeSection(); }
    &.showLocation #sectionLocation     { @include activeSection(); }

    #overlayDropDown > div {
        opacity: 1;
        -webkit-transition: opacity 200ms linear 400ms;
        transition: opacity 200ms linear 400ms;
    }

    #overlayDropDown {
        top: 0vh;
        transition: top 600ms cubic-bezier(0.86, 0, 0.07, 1);
        overflow: auto;

        .mainMenu,
        .secondaryMenu{
            top: 0;
            opacity: 1;
        }
    }
}
