.rubriqueTitre {
    margin-top: 20px;
}

.tiroirs {
    margin: 0;
    max-width: none;
    list-style-type: none;

    &>li {
        &>.toggleTiroir {
            position: relative;
            margin: 0;
            cursor: pointer;
        }

        &>.tiroir {
            display: none;
            max-width: 100%;
            opacity: 0;
            transform: translateY(10px);
            transition: opacity 300ms, transform 300ms;
        }

        &.open {
            &>.tiroir {
                opacity: 1;
                transform: translateY(0);
                transition: transform 500ms ease 100ms, opacity 500ms ease 100ms;
            }
        }
    }
}

.tiroirs.style1 {
    &>li {
        background: #fff;
        border-bottom: 1px solid #c6c6c6;

        &>.toggleTiroir {
            padding: 39px 120px 36px 6%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h6 {
                margin-bottom: 15px;
                width: 100%;
            }

            h2 {
                font-size: 22px;
                font-weight: 500;
                color: $ccgjDarkBlue;
                background: white;
                text-transform: none;
                transition: color 400ms, background 400ms;
                font-family: $Roboto;
                width: 100%;

                @media #{$down1200} {
                    font-size: 20px;
                }

                @media #{$down1024} {
                    font-size: 18px;
                }

                @media #{$down768} {
                    font-size: 16px;
                    padding-right: 75px;
                }

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    background: #4c4b4b;
                }

                &::after {
                    width: 22px;
                    height: 2px;
                    margin-top: -(2px / 2);
                    top: 50%;
                    right: 80px;
                    transition: background 400ms;

                    @media #{$down1300} {
                        right: 30px;
                    }

                    @media #{$down1024} {
                        right: 60px;
                    }

                    @media #{$down768} {
                        right: 30px;
                    }
                }

                &::before {
                    width: 2px;
                    height: 22px;
                    margin-top: -(22px / 2);
                    top: 50%;
                    right: 90px;
                    transition: transform 400ms, background 400ms;

                    @media #{$down1300} {
                        right: 40px;
                    }

                    @media #{$down1024} {
                        right: 70px;
                    }

                    @media #{$down768} {
                        right: 40px;
                    }
                }
            }
        }

        &>.tiroir {
            .tiroirContenu {
                padding: 0px 120px 35px 6%;
                max-width: 1080px;

                @media #{$down1024} {
                    padding: 0px 6% 35px 6%;
                }

                .adresse {
                    margin-bottom: 15px;
                }
            }
        }

        &.open {
            border-bottom: 3px solid $ccgjLightBlue;

            .toggleTiroir {
                h2 {
                    color: $ccgjLightBlue;

                    &::after,
                    &::before {
                        content: '';
                        position: absolute;
                        background: $ccgjLightBlue;
                    }

                    &::before {
                        background: transparent !important;
                        transform: scaleY(0);
                    }
                }
            }
        }

        &:hover .toggleTiroir h2 {
            color: $ccgjLightBlue;
        }

        &:first-child {
            border-top: 1px solid #c6c6c6;
        }

        &.hasImage {
            &>.toggleTiroir {
                padding: 39px 120px 36px 170px;
                min-height: 180px;

                img {
                    position: absolute;
                    top: 20px;
                    left: 0;
                }
            }
        }
    }

}


.tiroirs.style2 {
    &>li {
        background: $ccgjVeryLightBlue;
        margin-top: 20px;

        &>.toggleTiroir {
            padding: 39px 120px 36px 6%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h2 {
                font-size: 22px;
                font-weight: 500;
                color: $ccgjDarkBlue;
                text-transform: none;
                transition: color 400ms, background 400ms;
                font-family: $Roboto;
                width: 100%;

                @media #{$down1200} {
                    font-size: 20px;
                }

                @media #{$down1024} {
                    font-size: 18px;
                }

                @media #{$down768} {
                    font-size: 16px;
                    padding-right: 75px;
                }

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    background: #4c4b4b;
                }

                &::after {
                    width: 22px;
                    height: 2px;
                    margin-top: -(2px / 2);
                    top: 50%;
                    right: 80px;
                    transition: background 400ms;

                    @media #{$down1300} {
                        right: 30px;
                    }

                    @media #{$down1024} {
                        right: 60px;
                    }

                    @media #{$down768} {
                        right: 30px;
                    }
                }

                &::before {
                    width: 2px;
                    height: 22px;
                    margin-top: -(22px / 2);
                    top: 50%;
                    right: 90px;
                    transition: transform 400ms, background 400ms;

                    @media #{$down1300} {
                        right: 40px;
                    }

                    @media #{$down1024} {
                        right: 70px;
                    }

                    @media #{$down768} {
                        right: 40px;
                    }
                }
            }
        }

        &>.tiroir {
            .tiroirContenu {
                padding: 0px 120px 35px 6%;
                max-width: 1080px;

                @media #{$down1024} {
                    padding: 0px 6% 35px 6%;
                }

                .adresse {
                    margin-bottom: 15px;
                }

                p {
                    font-size: 15px;
                    margin: 10px 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                h4 {
                    font-size: 17px;
                    margin-top: 30px;
                    margin-bottom: 10px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

            }
        }

        &.open {
            .toggleTiroir {
                h2 {
                    color: $ccgjLightBlue;

                    &::after,
                    &::before {
                        content: '';
                        position: absolute;
                        background: $ccgjLightBlue;
                    }

                    &::before {
                        background: transparent !important;
                        transform: scaleY(0);
                    }
                }
            }
        }

        &:hover .toggleTiroir h2 {
            color: $ccgjLightBlue;
        }

        &:first-child {
            margin-top: 0;
        }

    }

}

.tiroirs.style3 {
    &>li {
        background: #fff;
        border-bottom: 1px solid #c6c6c6;

        &>.toggleTiroir {
            padding: 39px 120px 36px 6%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h6 {
                margin-bottom: 15px;
                width: 100%;
            }

            h2 {
                font-size: 22px;
                font-weight: 500;
                color: $ccgjDarkBlue;
                background: white;
                text-transform: none;
                transition: color 400ms, background 400ms;
                font-family: $Roboto;
                width: 100%;

                @media #{$down1200} {
                    font-size: 20px;
                }

                @media #{$down1024} {
                    font-size: 18px;
                }

                @media #{$down768} {
                    font-size: 16px;
                    padding-right: 75px;
                }

                @media #{$up330} {

                    &::after,
                    &::before {
                        content: '';
                        position: absolute;
                        background: #4c4b4b;
                    }

                    &::after {
                        width: 22px;
                        height: 2px;
                        margin-top: -(2px / 2);
                        top: 50%;
                        right: 80px;
                        transition: background 400ms;

                        @media #{$down1300} {
                            right: 30px;
                        }

                        @media #{$down1024} {
                            right: 60px;
                        }

                        @media #{$down768} {
                            right: 30px;
                        }

                        @media #{$down500} {
                            top: 60%;
                        }
                    }

                    &::before {
                        width: 2px;
                        height: 22px;
                        margin-top: -(22px / 2);
                        top: 50%;
                        right: 90px;
                        transition: transform 400ms, background 400ms;

                        @media #{$down1300} {
                            right: 40px;
                        }

                        @media #{$down1024} {
                            right: 70px;
                        }

                        @media #{$down768} {
                            right: 40px;
                        }

                        @media #{$down500} {
                            top: 60%;
                        }
                    }
                }
            }
        }

        &>.tiroir {
            .tiroirContenu {
                padding: 0px 120px 35px 6%;
                max-width: 1080px;

                @media #{$down1024} {
                    padding: 0px 6% 35px 6%;
                }

                .adresse {
                    margin-bottom: 15px;
                }
            }
        }

        &.open {
            border-bottom: 3px solid $ccgjLightBlue;

            .toggleTiroir {
                h2 {
                    color: $ccgjLightBlue;

                    &::after,
                    &::before {
                        content: '';
                        position: absolute;
                        background: $ccgjLightBlue;
                    }

                    &::before {
                        background: transparent !important;
                        transform: scaleY(0);
                    }
                }
            }
        }

        &:hover .toggleTiroir h2 {
            color: $ccgjLightBlue;
        }

        &:first-child {
            border-top: 1px solid #c6c6c6;
        }

        &.hasImage {
            &>.toggleTiroir {
                padding: 35px 120px 35px 170px;
                min-height: 180px;

                @media #{$down330} {
                    padding: 15px 120px 15px 130px;
                    min-height: 150px;
                }

                img {
                    position: absolute;
                    top: 20px;
                    left: 0;
                    width: 140px;

                    @media #{$down330} {
                        width: 110px;
                    }
                }
            }
        }
    }
}

