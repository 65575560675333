#overlayDropDownSearch {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: -100vh;
    background: $ccgjDarkBlue;
    background: $ccgjDarkBlue;
    z-index: 100;
    transition: top .6s cubic-bezier(0.19, 1, 0.22, 1);
    max-height: 650px;
    @media #{$down700} { max-height: none; }

    color: white;
    opacity: 1;

    .btnClose{
        position: absolute;
        top: 0;
        right: 50px;
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        padding: 38px 38px 38px 20px;
        transition: all ease-in 200ms;
        cursor: pointer;
        svg{
            height: 16px;
            width: 16px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -1px;
            .fill{
                stroke: white;
                transition: all ease-in 200ms;
            }
        }
        &:hover{
            color: $ccgjLightBlue;
            svg{
                .fill{
                    stroke: $ccgjLightBlue;
                }
            }
        }
    }

    .searchFormContainer{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        @media #{$down500} {
            top: 90px;
            transform: translateX(-50%);
        }

        .searchTitle{
            font-size: 50px;
            font-family: $barlow;
            font-weight: bold;
            text-transform: uppercase;

            @media #{$down900} { font-size: 40px; }
            @media #{$down600} { font-size: 30px; }

            hr{
                margin-top: 25px;
                margin-bottom: 70px;
                @media #{$down500} { margin-bottom: 30px; }
            }
        }

        .searchForm{
            position: relative;
            display: flex;
            .magnifierIcon{
                position: absolute;
                top: 50%;
                padding-left: 40px;
                transform: translateY(-50%);
                svg{
                    height: 24px;
                    width: 24px;
                    .fill{
                        fill: white;
                    }
                }
                @media #{$down400} { display: none; }
            }
            input,
            button{
                height: 70px;
                line-height: 70px;
                border:1px solid $ccgjLightBlue;
                padding: 0 35px;
                background-color: transparent;
                color: white;
                font-size: 17px;
                border-radius: 0;
                -webkit-appearance: none;
            }
            input{
                flex-grow: 1;
                border-right: 0;
                width: calc(100% - 145px);
                padding-left: 90px;
                @media #{$down400} {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                &::placeholder{
                    color: white;
                }
            }
            button{
                position: relative;
                width: 145px;
                cursor: pointer;
                font-size: 13px;
                padding: 0 25px;
                transition: all ease-in 0.3s;
                span.searchText{
                    //display: inline-block;
                    //padding-right: 10px;
                }
                svg{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) translateX(0);
                    width: 12px;
                    transition: all ease-in 0.3s;
                    margin-top: 1px;
                    .fill{
                        stroke: white;
                        fill: white;
                    }
                }
                &:hover{
                    background-color: $ccgjLightBlue;
                    /*svg{
                        transform: translateY(-50%) translateX(+25%); 
                    }*/
                }

                @media #{$down400} {
                    width: 70px;
                    padding: 0;
                    .searchText{
                        display: none;
                    }
                    svg{
                        transform: translateY(-50%) translateX(-50%);
                    }
                }
            }
        }

        & > .wrapper1200 {
            transition: opacity ease-in-out 0.6s, top ease-in-out 0.8s;
            transition-delay: 0.6s;
            position: relative;
            top: 60px;
            opacity: 0;
        }
    }

}


.showOverlayDropDownSearch {
    // css nécéssaire pour activer une section
    @mixin activeSection() {
        visibility: visible;
        opacity: 1;
        transition: opacity 200ms linear 400ms;
    }

    #overlayDropDownSearch{
        .searchFormContainer{
            & > .wrapper1200 {
                top: 0;
                opacity: 1;
            }
        }
    }

    #overlayDropDownSearch > div {
        opacity: 1;
        -webkit-transition: opacity 200ms linear 400ms;
        transition: opacity 200ms linear 400ms;
    }

    #overlayDropDownSearch {
        top: 0vh;
        transition: top 600ms cubic-bezier(0.86, 0, 0.07, 1);
    }
}
