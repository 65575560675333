select::-ms-expand { display: none; }
.chosen-disabled .chosen-single { cursor: default; }
.chosen-container-single .chosen-search { display: none; }
.chosen-container .chosen-results li.highlighted { color: $ccgjDarkBlue; }

select {
    display: block;
    width: 100%;
    opacity: 0;
}

.chosen-disabled {
    opacity: 0.35 !important;
    cursor: default;
}

.chosen-container {
    position: relative;
    display: inline-block;
    width: 100% !important;
    font-size: 15px;
    vertical-align: middle;
    user-select: none;
    z-index: 50;


	.chosen-single {
		display: block;
		position: relative;
		width: 100%;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		z-index: 49;
		transition: border 0.3s, border-radius 0.3s;

		span {
			display: block;
			text-overflow: ellipsis;
			white-space: nowrap;
            overflow: hidden;
		}

		div {
			display: block;
			position: absolute;
			width: 40px;
			height: 100%;
			top: 0;
			right: 0;
			transition: transform 0.3s;

			b { display: none; }
		}
	}

	.chosen-drop {
		position: absolute;
		width: 100%;
		top: 0;
		border: none;
		background: #fff;
		opacity: 0;
		visibility: hidden;
		z-index: 48;
		transition: padding-top 0.3s, opacity 0.3s, box-shadow 0.3s, visibility 0ms ease 0.3s;
	}

	&.chosen-with-drop .chosen-drop {
		top: 0;
		padding-top: 55px;
		visibility: visible;
		opacity: 1;
		box-shadow: 2px 2px 7px rgba(#000,0.1);
		transition: padding 0.3s, opacity 0.3s, box-shadow 0.3s;
	}

	.chosen-results {
		position: relative;
        max-height: 250px;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		li {
			display: none;
			padding: 15px 20px;
			margin: 0;
			font: 15px/20px $Roboto;
			list-style: none;
			word-wrap: break-word;
			-webkit-touch-callout: none;
            transition: color 0.2s;

			&.active-result {
				display: list-item;
				cursor: pointer;
				color: $ccgjDarkBlue;
			}
			&.disabled-result {
				display: list-item;
				color: rgba($ccgjDarkBlue, 0.4);
				cursor: default;
			}
			&.result-selected {
				font-family: $Roboto;
				background: $ccgjVeryLightBlue !important;
				cursor: default;
			}
		}
	}
}

.chosen-container-active {
	z-index: 50;

	&.chosen-with-drop .chosen-single span::after{
    	transform: rotate(-180deg);
	}
}

.chosen.style1 {
    &.extended {
        @media #{$up768} {
            width:500px;
        }
        & .chosen-container {
            @media #{$up768} {
                width: 300px !important;
            }
        }
    }

    select {
        position: relative;
        display: block;
        padding: 0;
        font: 13px/56px $Roboto;
        height: 56px;
        border-bottom: none;
        opacity: 1;
        color: $ccgjDarkBlue;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        outline: none;
        appearance: none;
        background: url('../images/content/icons/blue_arrow.svg') no-repeat right transparent;
        background-size: 11px 11px;
        background-origin: content-box;
        font-weight: 600;
    }

	.chosen-container {
        background: transparent;

		.chosen-single {
			padding: 0 45px 0 20px;
			font: 13px/56px $Roboto;
			color: $ccgjDarkBlue;
            border-bottom: 1px solid rgba($ccgjDarkBlue, 0);
            letter-spacing: 0;
            transition: background 0.2s, color 0.2s, border 0.2s;
            font-weight: 600;

            span {
                &:after{
                    content: '';
                    height: 9px;
                    width: 13px;
                    background: url(../images/content/icons/blue_arrow.svg) no-repeat center center;
                    background-size: 13px auto;
                    display: inline-block;
                    margin: 0 15px;
                    transition: all ease-in 0.3s;
                }
            }
		}

		.chosen-default {
			font: 15px/56px $Roboto;
			color: $ccgjDarkBlue;
            letter-spacing: 0;
		}

        .chosen-results {
            border-top: 1px solid rgba($ccgjDarkBlue, 0.5);
        }

        &.chosen-with-drop {
            .chosen-default {
                color: $ccgjDarkBlue;
                border-bottom: 1px solid rgba($ccgjDarkBlue, 0.05);
            }
        }

        .active-result.result-selected { color: $ccgjDarkBlue !important; }
        .active-result {
            color: $ccgjDarkBlue !important;
            transition: background 0.2s;

            &:hover {
                background: $ccgjVeryLightBlue !important;
            }
        }
	}
}

select {
    position: relative;
    display: block;
    font: 14px/45px $Roboto;
    height: 45px;
    border: none;
    border-bottom: 2px solid #ccd4dd;
    opacity: 1;
    color: $ccgjDarkBlue;
    //background: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus, &:active {
        outline: none !important;
        border-color:  $ccgjDarkBlue;
    }

    &.notEmpty{
        font: 20px/45px $Roboto;
    }
}
select::-ms-expand {
    display: none;
}

.select-field {
    position: relative;
    padding: 0;

    &:after {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 44px;
        content: '';
        cursor: pointer;
        pointer-events: none;
        z-index: 49;
        background: #fff url('../../images/icones/pointeBlanc.svg') no-repeat center;
		background-size: 10px auto;
    }
}
