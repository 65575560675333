.publicationsHeader{
    background-color: $ccgjVeryLightBlue;

    .pageTitle{
        position: relative;

        .titlePadding {
            margin-bottom: 48px;
            @media #{$down500} { margin-bottom: 20px; }
        }
    }

    #publicationsSelectFilter{
        z-index: 700;
        position: absolute;
        top: 50%;
        right: 0;
        width: 250px;
        transform: translateY(-50%);
        @media #{$down900} {
            position: static;
            transform: none;
            width: 100%;
            padding-bottom: 20px;
        }
    }

    .share{
        padding-bottom: 20px;
    }
}

#publications,
#publication_detail{

    .publications{
        .publication{
            font-size: 20px;
            line-height: 1.3em;//26px/20px
            color: $ccgjDarkBlue;
            font-weight: bold;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e6e8eb;
            padding-bottom: 30px;
            margin-bottom: 30px;
            transition: all ease-in 0.3s;
            .image{
                display: block;
                width: 120px;
                height: 120px;
                position: relative;
                overflow: hidden;
                span{
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
            .content{
                display: block;
                padding-left: 36px;
                width: calc(100% - 120px);
                text-align: left;
                .h6,
                .title{
                    display: block;
                }
                .h6{
                    margin-bottom: 12px;
                }
                @media #{$down700}{
                    .h6{
                        margin-bottom: 10px;
                    }
                }
                @media #{$down400}{
                    width: 100%;
                    padding-left: 0;
                    padding-top: 20px;
                }
            }

            &:hover{
                color: $ccgjLightBlue;
                .image{
                    span{
                        transform: scale(1.1);
                    }
                }
            }

            @media #{$down400}{ 
                display: block;
                .image{
                    width: 100%;
                    height: 215px;
                }
            }
        }

        @media #{$down1024}{
            &.others{
                padding-top: 0;
            }
        }

        &.featured{
            margin-top: -60px;
            .publication{
                border-bottom: 0;
                padding-bottom: 0;
                margin-top: 44px;
                @media #{$down1024}{ display: block; }
                .image{
                    width: 480px;
                    height: 430px;
                    @media #{$down1024}{ width:100%; }
                    @media #{$down700}{ height:340px; }
                    @media #{$down500}{ height:260px; }
                    @media #{$down400}{ height:200px; }
                }
                .content{
                    width: calc(100% - 480px);
                    @media #{$down1024}{
                        width:100%;
                        padding: 0;
                        padding-top: 30px;
                        padding-bottom: 30px;
                    }
                    .h6, .title, .excerpt{
                        width: 100%;
                        max-width: 580px;
                        display: block;
                    }
                    .title{
                        font-size: 35px;
                        text-transform: uppercase;
                        font-family: $barlow;
                        line-height: 1em;
                        margin-bottom: 35px;
                    }
                    .excerpt{
                        font-size: 16px;
                        line-height: 1.875em; //30px/16px
                        color: #acb6c0;
                        font-weight: normal;
                        margin-bottom: 35px;
                    }
                }

                &:hover{
                    .content{
                        .link{
                            padding: 0 0 0 35px;
                            &::before{
                                width: 25px;
                            }
                        }
                    }
                }
                &:first-child{
                    margin-top: 0;
                    .content{
                        padding-top: 60px;
                        @media #{$down1024}{ padding-top:30px; }
                    }
                }
            }
        }



    }
}

#publication_detail{
    .singleContent{
        h3:first-of-type{
            margin-top: 35px;
        }
    }
}